import { css } from "@emotion/css";
import { unit } from "shared-lib/src/utility/style";
import { MOBILE_THRESHOLD } from "../helper/body";

export const COLOUR = {
  WHITE: "#ffffff",
  BLACK: "#000000",
  DARK: "#272727",
  GRAY: "#c4c4c4",
  YELLOW: "#f2c218",
  GREEN: "#06c755",
  RED: "#a63232",
};

export const bg = {
  white: css({
    backgroundColor: COLOUR.WHITE,
  }),
  black: css({
    backgroundColor: COLOUR.BLACK,
  }),
  dark: css({
    backgroundColor: COLOUR.DARK,
  }),
  gray: css({
    backgroundColor: COLOUR.GRAY,
  }),
  yellow: css({
    backgroundColor: COLOUR.YELLOW,
  }),
  green: css({
    backgroundColor: COLOUR.GREEN,
  }),
  red: css({
    backgroundColor: COLOUR.RED,
  }),
};

export const text = {
  white: css({
    color: COLOUR.WHITE,
  }),
  black: css({
    color: COLOUR.BLACK,
  }),
  dark: css({
    color: COLOUR.DARK,
  }),
  gray: css({
    color: COLOUR.GRAY,
  }),
  yellow: css({
    color: COLOUR.YELLOW,
  }),
  green: css({
    color: COLOUR.GREEN,
  }),
  red: css({
    color: COLOUR.RED,
  }),
};

export const utility = {
  flexAuto: css({
    flex: 1,
  }),
  textXL: css({
    fontSize: unit(3, "rem"),
  }),
  textXXL: css({
    fontSize: unit(4, "rem"),
  }),
  textShadow: css({
    textShadow: "2px 2px 8px #000000",
  }),
  forceLineBreak: css({
    whiteSpace: "pre-wrap",
  }),
};

export const width = {
  _100px: css({
    width: unit(100, "px"),
  }),
  _200px: css({
    width: unit(200, "px"),
  }),
  maxContent: css({
    width: "max-content",
  }),
  _100vw: css({
    width: unit(100, "vw"),
  }),
};

export const cursor = {
  Pointer: css({
    cursor: "pointer!important",
  }),
};

export const img = {
  general: css({
    width: "480px",
    height: "270px",
    [`@media(max-width: ${MOBILE_THRESHOLD}px)`]: {
      width: "320px",
      height: "180px",
    },
  }),
  // medium: css({
  //   width: ""
  // })
};

export const border = {
  black: css`
    border-color: ${COLOUR.BLACK} !important;
  `,
};

export { unit };
