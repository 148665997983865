import { subPath } from "shared-lib/src/types/api/User";
import { AxiosRequestConfig } from "axios";
import { getAxios } from "..";

const UserAPI = (config: AxiosRequestConfig) => {
  const url = subPath + config.url;
  return getAxios({ ...config, url });
};

export default UserAPI;
