import { createStore, createUseSelector, ReducerAction } from "atom-redux/dist/cjs/main";
import { deepclone } from "atom-redux/dist/cjs/utility";
import { API, Types } from "shared-lib";
import { useNavigate } from "react-router-dom";

type Department = Types.Department.Def;

type UserType = "guest" | "member";

type AppStore = {
  // userId: string | null;
  userType: UserType;
  // isApartmentMember: boolean | null;
  user: API.User.CustomType.Info | null;
  guest: Omit<Types.Guest.Type, "id" | "userNo"> | null;
  navigate: ReturnType<typeof useNavigate> | null;
  // userDepartment: Department | null;
};

const initialAppStore: AppStore = {
  // userId: null,
  userType: "guest",
  // isApartmentMember: null,
  user: null,
  navigate: null,
  guest: null,
  // userDepartment: null,
};

const initialiseAppStore = () => {
  return deepclone(initialAppStore);
};

type SimpleReducerAction<State extends Record<string, unknown>> =
  | ReducerAction<"set", Partial<State>>
  | ReducerAction<"reset">;

const simpleReducer = async <State extends Record<string, unknown>>(
  initialise: () => State,
  state: State,
  action: SimpleReducerAction<State>,
): Promise<State> => {
  if (action.type === "set") {
    const { payload } = action as unknown as { payload: Partial<State> };
    return {
      ...state,
      ...payload,
    };
  }
  if (action.type === "reset") {
    return initialise();
  }
  return state;
};

const createSimpleReducer = <State extends Record<string, unknown>>(initialise: () => State) => {
  return simpleReducer.bind<
    undefined,
    () => State,
    [state: State, action: SimpleReducerAction<State>],
    Promise<State>
  >(undefined, initialise);
};

const appStore = createStore<AppStore, SimpleReducerAction<AppStore>>(
  initialiseAppStore,
  createSimpleReducer<AppStore>(initialiseAppStore),
);

const useAppStore = createUseSelector(appStore);

const setAppState = (payload: Partial<AppStore>) => {
  appStore.dispatch({
    type: "set",
    payload,
  });
};

export { createSimpleReducer, useAppStore, setAppState };
export type { AppStore, UserType, Department, SimpleReducerAction };
