import "bootstrap/dist/css/bootstrap-utilities.css";
import "../style/main.css";
import "../style/font/index.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "@/components/Progress/Progress.styles.css";
import { setAppState } from "./App.store";
import { useEffect, useState, useRef, useDebugValue } from "react";
import Footer from "@/components/Footer";
import { css } from "@emotion/css";
import { nanoid } from "nanoid";
import { ModalContainer } from "../components/v2/Modal";
import { AppProps } from "next/app";
import NavbarMemoed from "@/components/Navbar";
import { Types } from "shared-lib";
import { FCWithChildren } from "../Props";
import Head from "next/head";
import { prepareReact, REACT } from "atom-redux/dist/cjs";
import Toaster from "./Toaster";
import Progress from "@/components/Progress";
import { StrictMode } from "react";
import refreshLogin from "@/api/user/refreshLogin";
import getOwn from "@/api/user/getOwn";

if (Object.isFrozen(REACT) === false) {
  prepareReact({
    useEffect: useEffect as NonNullable<(typeof REACT)["useEffect"]>,
    useState,
    useRef,
    useDebugValue,
  });
}

const styles = {
  App: css({
    position: "relative",
    width: "100vw",
    // minHeight: "100vh",
    marginBottom: "4rem",
  }),
};

type User = Types.User.Type;

export const userMockUpData: User = {
  id: nanoid(),
  username: "testUser",
  firstName: "ต้นน้ำ",
  lastName: "อาลีบาบา",
  birthDate: 962902800000,
  email: "nam3tn@gmail.com",
  phoneNumber: "0123456789",
  lineId: "tn2254",
  department: "customer",
  role: "customer",
  memberExpiryDate: 1670691600000,
  isApartmentMember: true,
} as User;

const Main: FCWithChildren = ({ children }) => {
  return <div className={styles.App}>{children}</div>;
};

const SET_FAKE_USER = process.env.NODE_ENV === "production" ? false : false;

const App: FCWithChildren<AppProps> = ({ Component, pageProps }) => {
  useEffect(() => {
    const onMount = async () => {
      if (SET_FAKE_USER) {
        setAppState({
          userType: "member",
          user: {
            ...userMockUpData,
          },
        });
        return;
      }
      setAppState({
        userType: "guest",
      });

      try {
        await refreshLogin();
        const getOwnRes = await getOwn();
        const user = getOwnRes.data.data;
        setAppState({ user, userType: "member" });
      } catch {
        //
      }
    };
    const id = window.setTimeout(onMount);

    return () => {
      window.clearTimeout(id);
    };
  }, []);

  // console.log("App rendering");

  return (
    <StrictMode>
      <Head>
        <link rel="apple-touch-icon" href="/logo192.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"
        />
        <meta name="theme-color" content="#000000" />
        <title>Xkhanek Place</title>
      </Head>
      <Progress />
      {/* <NavbarMemoed /> */}
      <ModalContainer />
      <Toaster />
      <Main>
        <Component {...pageProps} />
      </Main>
      {/* <Footer /> */}
    </StrictMode>
  );
};

export default App;
export type {};
