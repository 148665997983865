import { CSSProperties } from "react";

export namespace Style {
  export type Unit = "px" | "vw" | "vh" | "rem" | "em" | "%";
  export type CSSObjecte<Key extends string> = {
    [key in Key]: CSSProperties;
  };
}

/** CSS unit string helper function
 *
 * @example
 * unit(10, "rem") => "10rem"
 */
export const unit = (x: number, unit?: Style.Unit) => {
  return `${x}${unit || ""}`;
};
