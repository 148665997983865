import { pubsub } from "atom-pubsub";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { useLazyRef } from "atom-redux/dist/cjs";

const bodyOnClickEvent = "body-onclick";

const BODY_ONCHANGE = "document-change";

export const MOBILE_THRESHOLD = 600; //px

const setUpUseBody = () => {
  if (typeof window !== "undefined") {
    if (window.document.body.onclick === null) {
      window.document.body.onclick = (e) => {
        pubsub.publish(bodyOnClickEvent, e);
      };
    }
    if (window.document.body.onresize === null) {
      window.document.body.onresize = () => {
        pubsub.publish(BODY_ONCHANGE);
      };
    }
  }
};
setUpUseBody();

const isMobile = () => {
  if (typeof window !== "undefined") {
    return document.body.clientWidth < MOBILE_THRESHOLD;
  }
  return false;
};

const useOnBodyResize = (onresize: () => unknown) => {
  // const id = useRef("");

  useEffect(() => {
    const id = pubsub.subscribe(BODY_ONCHANGE, onresize);
    return () => {
      pubsub.unsubscribe(BODY_ONCHANGE, id);
    };
    // eslint-disable-next-line
  }, []);
};

const useBodyClose = (isShow: boolean, close: () => void) => {
  const id = useLazyRef(nanoid);

  useEffect(() => {
    return () => {
      pubsub.unsubscribe(bodyOnClickEvent, id.current);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    pubsub.unsubscribe(bodyOnClickEvent, id.current);
    if (isShow) {
      const setId = () => {
        id.current = pubsub.subscribe(bodyOnClickEvent, () => {
          // if (targetRef.current !== e.target) {
          close();
          // }
        });
      };
      window.setTimeout(setId, 0);
      return;
    }
    return pubsub.unsubscribe(bodyOnClickEvent, id.current);
    // eslint-disable-next-line
  }, [isShow]);
};

const useMobileQuery = () => {
  const [_isMobile, setIsMobile] = useState(() => {
    return isMobile();
  });

  useOnBodyResize(() => {
    return setIsMobile(() => {
      return isMobile();
    });
  });

  return { isMobile: _isMobile };
};

export { useOnBodyResize, isMobile, useBodyClose, useMobileQuery };
