// import "./Progress.styles.css";
import { memo, useEffect } from "react";
import NProgress from "nprogress";
import Router from "next/router";
import { useRef } from "react";
import { useMemo } from "react";

const startPosition = 0.3,
  stopDelayMs = 200,
  showOnShallow = true;

const ProgressBar: React.FC = () => {
  const timer = useRef<number | null>(null);

  const routeChangeStart = useMemo(() => {
    return (
      _: string,
      {
        shallow,
      }: {
        shallow: boolean;
      },
    ) => {
      if (!shallow) {
        NProgress.set(startPosition);
        NProgress.start();
      }
    };
  }, []);

  const routeChangeEnd = useMemo(() => {
    return (
      _: string,
      {
        shallow,
      }: {
        shallow: boolean;
      },
    ) => {
      if (!shallow || showOnShallow) {
        if (typeof timer.current === "number") {
          window.clearTimeout(timer.current);
        }
        timer.current = window.setTimeout(() => {
          NProgress.done(true);
        }, stopDelayMs);
      }
    };
  }, []);

  useEffect(() => {
    // if (options) {
    //   NProgress.configure(options);
    // }
    Router.events.on("routeChangeStart", routeChangeStart);
    Router.events.on("routeChangeComplete", routeChangeEnd);
    Router.events.on("routeChangeError", routeChangeEnd);
    return () => {
      Router.events.off("routeChangeStart", routeChangeStart);
      Router.events.off("routeChangeComplete", routeChangeEnd);
      Router.events.off("routeChangeError", routeChangeEnd);
    };
  }, [routeChangeStart, routeChangeEnd]);

  return null;
};

export default memo(ProgressBar);
