import UserAPI from ".";
import { GetOwn } from "shared-lib/src/types/api/User";
import { AxiosResponse } from "axios";

export default function getOwn(): Promise<AxiosResponse<GetOwn.Response>> {
  return UserAPI({
    url: GetOwn.subPath,
    method: "get",
  });
}
