import { css } from "@emotion/css";
import { toast as _toast } from "react-hot-toast";
import { COLOUR } from "../style";

export const toastClassName = css`
  background-color: ${COLOUR.YELLOW} !important;
  color: ${COLOUR.BLACK} !important;
`;

type Toast = typeof _toast;

export const toast = (() => {
  const toast: Toast = ((message, opt) => {
    return _toast(message, {
      ...opt,
      className: toastClassName,
    });
  }) as Toast;

  toast.remove = (id) => {
    _toast.remove(id);
  };

  const keys: (keyof Toast)[] = ["loading", "error", "success"];

  for (const key of keys) {
    const val = _toast[key];
    (toast[key] as typeof val) = ((message, opt) => {
      return (_toast[key] as Toast)(message, {
        ...opt,
        className: toastClassName,
      });
    }) as Toast;
  }

  // toast.e

  return toast;
})();
