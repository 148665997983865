import axios, { AxiosRequestConfig } from "axios";

const baseConfig: AxiosRequestConfig = {
  baseURL: process.env.NEXT_PUBLIC_API_DOMAIN,
  withCredentials: true,
};

Object.freeze(baseConfig);

export const getAxios = async (config: AxiosRequestConfig) => {
  return axios({ ...baseConfig, ...config });
};
