import { memo } from "react";
import { Toaster as TOASTER } from "react-hot-toast";
import { toastClassName } from "../components/Toaster";

const Toaster: React.FC = () => {
  return (
    <TOASTER
      toastOptions={{
        className: toastClassName,
        success: {
          className: toastClassName,
        },
        error: {
          className: toastClassName,
        },
        loading: {
          className: toastClassName,
        },
      }}
    />
  );
};

export default memo(Toaster, () => {
  return true;
});
