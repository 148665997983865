import { createStore, createUseSelector } from "atom-redux";
import equal from "fast-deep-equal";
import React, { memo, useEffect } from "react";

const { ModalContainer, useModal } = (() => {
  type Modal = {
    render: React.FC<Record<never, never>> | null;
    showing: boolean;
  };

  const initialise = (): Modal => {
    return {
      render: null,
      showing: false,
    };
  };

  const store = createStore<
    Modal,
    | { type: "set-render"; payload: React.FC<Record<never, never>> }
    | {
        type: "show";
      }
    | {
        type: "hide";
      }
    | {
        type: "reset";
      }
  >(initialise, async (current, action) => {
    const { type } = action;

    if (type === "set-render") {
      return { ...current, render: action.payload };
    }

    if (type === "hide") {
      return { ...current, showing: false };
    }

    if (type === "show") {
      return { ...current, showing: true };
    }

    if (type === "reset") {
      return initialise();
    }

    return current;
  });

  const useInternalModal = createUseSelector(store);

  const dispatch = store.dispatch.bind(store);

  const dispatchObj = {
    setRender: (render: React.FC) => {
      return dispatch({ type: "set-render", payload: render });
    },
    show() {
      return dispatch({ type: "show" });
    },
    hide() {
      return dispatch({ type: "hide" });
    },
  };

  const useModal = () => {
    return dispatchObj;
  };

  const ModalContainer: React.FC = () => {
    useEffect(() => {
      return () => {
        dispatch({ type: "reset" });
      };
    }, []);

    const { render, showing } = useInternalModal(
      (state) => {
        return state;
      },
      (pv, cv) => {
        return equal(pv, cv) === false;
      },
    );

    if (showing && render !== null) {
      return render({});
    }

    return null;
  };

  return {
    ModalContainer: memo(ModalContainer, () => {
      return true;
    }),
    useModal,
  };
})();

const modalAPI = useModal;

export { ModalContainer, useModal, modalAPI };
